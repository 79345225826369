import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import Fade from 'react-reveal/Fade'

import { Poi, Props as PoiProps } from './Poi'

export interface Props {
  description?: string
  poi: PoiProps[]
  title?: string
}

export const PointOfInterest = memo(function PointOfInterest({
  description,
  poi,
  title,
}: Props) {
  if (poi.length < 1) {
    return null
  }

  return (
    <Container>
      {title ? (
        <Fade bottom distance="3.75rem">
          <Title>{title}</Title>
        </Fade>
      ) : null}
      {description ? (
        <Fade bottom distance="3.75rem">
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        </Fade>
      ) : null}
      <Fade bottom distance="3.75rem">
        <Wrapper row wrap space="between">
          {poi.map((item, index) => (
            <Poi key={index} {...item} />
          ))}
        </Wrapper>
      </Fade>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralDark1};
  padding: 12.5rem 10vw;

  @media (max-width: 1119px) {
    padding: 5.625rem 8.454vw 1.875rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 4.375rem;
  line-height: 5.25rem;

  @media (max-width: 1119px) {
    font-size: 2.5rem;
    line-height: 3rem;
  }
`

const Description = styled.div`
  max-width: 540px;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.375rem;
  line-height: 1.875rem;
  margin-top: 1.125rem;

  @media (max-width: 1023px) {
    max-width: none;
  }
`

const Wrapper = styled(FlexBox)`
  margin-top: 3.125rem;

  @media (max-width: 1023px) {
    margin-top: 2.5rem;
  }
`

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import Fade from 'react-reveal/Fade'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  IBEID?: string
  items: ItemProps[]
  title?: string
}

export const BusinessBox = memo(function BusinessBox({
  IBEID,
  items,
  title,
}: Props) {
  if (!items) {
    return null
  }

  return (
    <Container>
      {title ? (
        <Fade bottom distance="3.75rem">
          <Title>{title}</Title>
        </Fade>
      ) : null}
      <Fade bottom distance="3.75rem">
        <Wrapper row wrap space="between">
          {items.map((item, index) => (
            <Item key={index} IBEID={IBEID} {...item} />
          ))}
        </Wrapper>
      </Fade>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralDark1};
  &:after {
    content: '';
    display: block;
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
    height: 6.875rem;
  }

  @media (max-width: 1023px) {
    &:after {
      display: none;
    }
  }
`

const Wrapper = styled(FlexBox)`
  padding: 0 10vw 8.75rem;

  @media (max-width: 1199px) {
    padding: 0 8.454vw 5.625rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 3rem;
  padding: 0 10vw;
  text-align: center;

  @media (max-width: 1199px) {
    padding: 0 8.454vw;
  }
`

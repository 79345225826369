import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export interface Props {
  description?: string
  features?: string[]
  featuresTitle?: string
  IBEID?: string
  image?: ImageProps
  info?: string
  languageCode: string
  languagePrefix: string
  title?: string
}

export const Item = memo(function Item({
  description,
  features,
  featuresTitle,
  IBEID,
  image,
  info,
  languageCode,
  languagePrefix,
  title,
}: Props) {
  return (
    <Container>
      <Background>
        {image ? <LazyLoadImage {...image} /> : null}
        <Wrapper>
          <BoxContent className="box-content">
            {title ? <Title className="box-title">{title}</Title> : null}
            {description ? (
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            ) : null}
            <CTA
              URL={`https://booking.blusuitehotel.it/book/simple/step1?hotel=${IBEID}&lang=${
                languagePrefix ? languagePrefix : 'it'
              }`}
              label={useVocabularyData('book-now', languageCode)}
              rel="noopener"
              target="_blank"
            />
          </BoxContent>
        </Wrapper>
        {info ? (
          <Info
            className="box-info"
            dangerouslySetInnerHTML={{ __html: info }}
          />
        ) : null}
      </Background>
      {featuresTitle ? <FeaturesTitle>{featuresTitle}</FeaturesTitle> : null}
      {features ? (
        <Features row wrap>
          {features.map((item, index) => (
            <Feature key={index}>
              {
                // @ts-ignore
                item.label
              }
            </Feature>
          ))}
        </Features>
      ) : null}
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <Media lessThan="ipadHorizontal">
          <CTA
            URL={`https://booking.blusuitehotel.it/book/simple/step1?hotel=${IBEID}&lang=${
              languagePrefix ? languagePrefix : 'it'
            }`}
            label={useVocabularyData('book-now', languageCode)}
            rel="noopener"
            target="_blank"
          />
        </Media>
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.div`
  width: calc(50% - 0.75rem);
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  margin-top: 3.75rem;

  @media (max-width: 1023px) {
    width: calc(50% - 0.375rem);
  }

  @media (max-width: 767px) {
    width: 100%;
    margin-top: 3.75rem;
  }
`

const Background = styled.div`
  background: ${({ theme }) => theme.colors.variants.neutralDark2};
  overflow: hidden;
  padding-bottom: 62%;
  position: relative;
  &:hover {
    &:before {
      opacity: 0.7;
    }
    img {
      transform: scale(1.1);
    }
    .box-content,
    .box-title {
      transform: translateY(0);
    }
    .box-info {
      opacity: 0;
    }
  }
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    backface-visibility: hidden;
    transition: 0.3s ease-in-out;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.25s ease-in-out;
  }

  @media (max-width: 1199px) {
    padding-bottom: 75%;
  }

  @media (max-width: 1023px) {
    padding-bottom: 100%;
    &:hover {
      &:before {
        opacity: 0.5;
      }
      img {
        transform: scale(1);
      }
      .box-content {
        transform: translateY(100%);
      }
      .box-title {
        transform: translateY(-100%);
      }
      .box-info {
        opacity: 1;
      }
    }
  }
`

const Wrapper = styled.div`
  overflow: hidden;
  position: absolute;
  bottom: 1.875rem;
  right: 3.75rem;
  left: 1.875rem;
  z-index: 2;

  @media (max-width: 1023px) {
    right: 1.875rem;
  }
`

const BoxContent = styled.div`
  transition: 0.3s ease-in-out;
  transform: translateY(100%);
`

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  font-weight: 400;
  line-height: 2.25rem;
  text-transform: uppercase;
  transition: 0.3s ease-in-out;
  transform: translateY(-100%);

  @media (max-width: 1023px) {
    text-align: center;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.75rem;
  margin-top: 0.5rem;

  @media (max-width: 1023px) {
    display: none;
  }
`

const CTA = styled(Button)`
  background: ${({ theme }) => theme.colors.variants.primaryLight1};
  margin-top: 1.5rem;
  &:after {
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
  }

  @media (max-width: 1023px) {
    margin-top: 1.25rem;
  }

  @media (max-width: 767px) {
    width: 100%;
    justify-content: center;
  }
`

const Info = styled.div`
  width: 50%;
  background: ${({ theme }) => theme.colors.variants.primaryLight1};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  line-height: 1.375rem;
  padding: 2.125rem 2.5rem;
  position: absolute;
  top: 0;
  right: 0;
  transition: 0.3s ease-in-out;
  z-index: 3;

  @media (max-width: 1199px) {
    width: 100%;
    padding: 1.875rem;
  }
`

const FeaturesTitle = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryLight1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.75rem;
  margin-top: 1.875rem;

  @media (max-width: 1023px) {
    margin-top: 1.25rem;
  }
`

const Features = styled(FlexBox)``

const Feature = styled.div`
  width: 50%;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  line-height: 1.75rem;
  padding: 0 1.5rem 0 0.75rem;
  position: relative;
  &:before {
    content: '';
    width: 0.25rem;
    height: 0.25rem;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    border-radius: 50%;
    position: absolute;
    top: 0.75rem;
    left: 0;
  }

  @media (max-width: 1023px) {
    width: 100%;
    padding-right: 0;
  }
`
